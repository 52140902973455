import React from 'react';
import './App.css';
import Routes from "./routeur/Routes";

function App() {
  return (
      <Routes/>
  );
}

export default App;
